<template>
  <Layout :footer-style="1">
		<div class="slider-area slider-style-1 variation-default height-400 bg_image"
				 data-black-overlay="7"
				 :style="{'background-image': `url(${require(`@/assets/images/banner/softnext.png`)})`}">
			<div class="container">
				<div class="row">
					<div class="col-lg-12">
						<div class="inner">
							<h1 class="title" style="font-size: 36px;" data-aos="fade-up" data-aos-delay="150">守内安邮件安全</h1>
							<h3 class="title mt--40" style="font-size: 24px;" data-aos="fade-up" data-aos-delay="150">提供SPAM SQR 邮件安全网关、Mail SQR Expert 邮件审计管理、<br>Mail Archiving Expert 电子邮件归档管理等邮件安全解决方案。</h3>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="container">
			<Breadcrumb current-url="邮件安全"/>
		</div>

		<div class="rn-service-area rn-section-gapBottom pt--100 area-2">
			<div class="container">
				<div class="row row--15 service-wrapper">
					<div class="col-lg-4 col-md-6 col-sm-6 col-12">
						<div class="service text-center service__style--1 radius mt--25`"
								 data-aos="fade-up"
								 data-aos-delay="70">
							<div class="icon">
								<img class="p-img" src="@/assets/images/pdt/pdt_item02.svg" alt="SPAM SQR"/>
							</div>
							<div class="content">
								<h4 class="title w-600 mt--50">SPAM SQR <br>邮件安全网关</h4>
								<p>垃圾邮件与威胁邮件双核过滤<br>
									云端指纹库，威胁特征实时更新<br>
									勒索、APT、BEC诈骗邮件拦截示警</p>
								<p>
									<router-link to="/softnext/spam-sqr" class="more">
										<span>了解更多</span>
										<img class="i1" src="@/assets/images/home/u45.png">
										<img class="i2" src="@/assets/images/home/u45.png">
									</router-link>
								</p>
							</div>
						</div>
					</div>

					<div class="col-lg-4 col-md-6 col-sm-6 col-12">
						<div class="service text-center service__style--1 radius mt--25`"
								 data-aos="fade-up"
								 data-aos-delay="70">
							<div class="icon">
								<img class="p-img" src="@/assets/images/pdt/pdt_item03.svg" alt="Mail SQR Expert"/>
							</div>
							<div class="content">
								<h4 class="title w-600 mt--50">Mail SQR Expert<br> 邮件防泄密（审计）管理</h4>
								<p>邮件 DLP 防止数据外泄<br>
									落实分权与弹性邮件政策管理<br>
									符合证据保存期限的生命周期管理</p>
								<p>
									<router-link to="/softnext/mail-sqr-expert" class="more">
										<span>了解更多</span>
										<img class="i1" src="@/assets/images/home/u45.png">
										<img class="i2" src="@/assets/images/home/u45.png">
									</router-link>
								</p>
							</div>
						</div>
					</div>

					<div class="col-lg-4 col-md-6 col-sm-6 col-12">
						<div class="service text-center service__style--1 radius mt--25`"
								 data-aos="fade-up"
								 data-aos-delay="70">
							<div class="icon">
								<img class="p-img" src="@/assets/images/pdt/pdt_item04.svg" alt="Mail Archiving Expert"/>
							</div>
							<div class="content">
								<h4 class="title w-600 mt--50">Mail Archiving Expert <br>邮件归档管理</h4>
								<p>搜寻速度与存储空间的完美平衡<br>
									历史邮件转置，免除寻找旧信困扰<br>
									无缝整合使用者查询与调阅习惯</p>
								<p>
									<router-link to="/softnext/mail-archiving-expert" class="more">
										<span>了解更多</span>
										<img class="i1" src="@/assets/images/home/u45.png">
										<img class="i2" src="@/assets/images/home/u45.png">
									</router-link>
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
  </Layout>
</template>

<script>
import Layout from '@/components/common/Layout'
import Icon from '@/components/icon/Icon'
import Separator from '@/components/elements/separator/Separator'
import SectionTitle from '@/components/elements/sectionTitle/SectionTitle'
import Breadcrumb from '@/components/elements/breadcrumb/Breadcrumb'
export default {
  name: 'Product',
  components: {
    SectionTitle,
    Separator,
    Icon,
    Breadcrumb,
    Layout
  },
  data() {
    return {
    }
  }
}
</script>
<style lang="scss" scoped>
.display-block{
  display: block;
}
.area-2{
  .icon {
		background-color: #76a0de;
		padding: 0;
		border-radius: 50%;
		width: 120px;
		height: 120px;
		margin: 0 auto;
    .p-img {
      width: 120px;
      height: 120px;
			vertical-align: middle;
    }
  }
  .more{
    display: inline-flex;
    color: #2261AA;
    img {
      width: 27px;
      height: 27px;
    }
    .i1{
      margin-left: -4px;
    }
    .i2{
      margin-left: -20px;
    }
  }
}
.product{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  .pic{
    width: 220px;
  }
  .about{
    margin-left: 40px;
    color: #000000;
    .title{
      font-size: 32px;
      font-weight: bold;
    }
    .content{
      margin-top: 12px;
      font-size: 20px;
    }
  }
}
.company-logo{
  width: 320px;
}
.company-info{
  font-size: 24px;
  color: #000000;
  text-align: left;
  margin-top: 20px;
}
</style>
