<template>
  <Layout :footer-style="1">
		<div class="slider-area slider-style-1 variation-default height-400 bg_image"
				 data-black-overlay="2"
				 :style="{'background-image': `url(${require(`@/assets/images/banner/softnext1.jpg`)})`}">
		</div>
    <div class="container">
			<div id="area1" class="main-title"><img src="https://www.softnext.com.cn/images/icon_title.svg">SPAM SQR 邮件安全网关</div>
			<div class="pdttext content">
				<p>新一代邮件过滤系统，高效过滤普通垃圾邮件与高危钓鱼，病毒邮件。在全面安全保护的同时提供差异行为管理功能。即可满足用户自主管理放行邮件，亦可管控恶意邮件流窜。同时垃圾与威胁邮件双核过滤技术，拥有完善的特征库、指纹库和恶意网址数据库云端差分更新技术，搭载流量控制防御引擎、社交工程侦测引擎，附件内容分析引擎，有效缩短 0day 攻击的风险，提升系统整体拦截效能。网关架构于企业邮件服务器前，有效降低各式邮件攻击风险，如 BEC，垃圾/广告邮件、钓鱼邮件(社交工程)、伪造攻击邮件、病毒邮件、携带病毒附件邮件等。</p>
				<h3 class="text-center blue03">多层次过滤图</h3>
				<p class=" text-center "><img src="https://www.softnext.com.cn/images/pic_spam.svg" class="img-responsive" alt="SPAM SQR多层次过滤图"></p>

				<p class="subtitle blue02">产品特色</p>
				<div class="row scrollpoint sp-effect5 active " data-aos="fade-up" data-aos-delay="150">
					<div class="col-sm-6  mg_b50">
						<p class="text-center"><img class="img-circle border_red" src="https://www.softnext.com.cn/images/ssqr_item01.svg" width="100" height="100" alt=""></p>
						<p class="subtitle02 blue03 text-center">N-Tier 多重引擎、层层过滤，<br>
							防御已知与未知风险 </p>
						<ul class="pditem">
							<li>连线层防护：阻挡瞬时大量邮件攻击，节省带宽，降低服务器负载。</li>
							<li>恶意邮件隔离：将精心伪装的威胁邮件进行隔离，降低用户接触恶意邮件的风险。</li>
							<li>垃圾邮件拦截：如垃圾/广告邮件等非威胁邮件，可选发送明细，让用户自行选择。</li>
							<li>诈骗邮件示警：检查邮件伪造痕迹并分析来源，当邮件放行至用户端时加强警示。</li>
						</ul>
					</div>

					<div class="col-sm-6 mg_b50">
						<p class="text-center"><img class="img-circle border_red" src="https://www.softnext.com.cn/images/ssqr_item02.svg" width="100" height="100" alt=""></p>
						<p class="subtitle02 blue03 text-center">自定义高级邮件管理 <br><br></p>
						<ul class="pditem">
							<li>SPAM SQR 允许管理员自定义查看及修改过滤标准为不同群体设置 可查看内容。</li>
							<li>基于整套管理规则 SPAM SQR 可以对邮件自定义查看、流量管理，拦截，通知或自动转发 。</li>
							<li>SPAM SQR 可以依据公司组织成员的岗位职能来定义和分配管理任 务和接口权限，让权限任务能按预定义方式继承生效。</li>
						</ul>
					</div>
				</div>
				<div class="row scrollpoint sp-effect5 active " data-aos="fade-up" data-aos-delay="150">
					<div class="col-sm-6">
						<p class="text-center"><img class="img-circle border_red" src="https://www.softnext.com.cn/images/ssqr_item03.svg" width="100" height="100" alt=""></p>
						<p class="subtitle02 blue03 text-center">内控管理，一步解决“内忧外患” </p>
						<ul class="pditem">
							<li>待发邮件过滤，防止内部滥发恶意邮件而被列入 RBL。</li>
							<li>外发账号速率管控，防止内部中毒账号外发垃圾邮件。</li>
							<li>密码防猜，降低账号被盗风险（建议搭配密码强度检测）。</li>
						</ul>
					</div>

					<div class="col-sm-6">
						<p class="text-center"><img class="img-circle border_red" src="https://www.softnext.com.cn/images/ssqr_item04.svg" width="100" height="100" alt=""></p>
						<p class="subtitle02 blue03 text-center">个性化管理，接收查信更便利</p>
						<ul class="pditem">
							<li>可依个人需求定时发送拦截明细，方便用户取回或反馈管理员。</li>
							<li>提供 MySPAM 个性化 web 服务，如系统自动回复，自动，密件抄送与个人黑白名单，访问拦截明细。</li>
						</ul>
					</div>
				</div>
				<p id="area2" class="subtitle blue02">功能列表</p>
				<div class="list02 scrollpoint sp-effect5 active " data-aos="fade-up" data-aos-delay="150">
					<table class="table table-hover ">
						<thead>
						<tr>
							<th width="100%" colspan="2" class="text-left">邮件过滤 / 防御功能</th>
						</tr>
						</thead>
						<tbody>
						<tr>
							<td class="midR col-sm-2b col-xs-3">DoS 防御</td>
							<td>针对瞬间大量邮件攻击或联机数进行管控及阻挡，可避免邮件主机因遭受攻击而瘫痪服务。</td>
						</tr>
						<tr class="tr01">
							<td class="midR">流量管控</td>
							<td>检查企业内部合法收信账号，灰名单流量控制，防御外部字典式攻击</td>
						</tr>
						<tr>
							<td class="midR">RBL</td>
							<td>支持外部组织名单和自定义名单，自动进行人机验证并拦截机器投放邮件</td>
						</tr>
						<tr class="tr01">
							<td class="midR">DKIM</td>
							<td>拒绝、阻止邮件地址伪造攻击</td>
						</tr>
						<tr>
							<td class="midR">SPF</td>
							<td>检查邮件是否由经过授权的邮件服务器发出，拦截伪装网域对内发送的假冒邮件</td>
						</tr>
						<tr class="tr01">
							<td class="midR">DMARC</td>
							<td>运用 SPF 和 DKIM 机制验证邮件发件人是否来自合法网域，有效识别伪造邮件，避免遭受 BEC 攻击</td>
						</tr>
						<tr>
							<td class="midR">黑白名單</td>
							<td>支持系统 / 个人黑白名单，并可对白名单添加防伪验证制</td>
						</tr>
						<tr class="tr01">
							<td class="midR">ASRC 指纹辨识</td>
							<td>垃圾、威胁双库双引擎，自动抓取分析全球垃圾、威胁数据并全面下发防御策略，此外，运用云端差分机制，更新更迅速防护更全面</td>
						</tr>
						<tr>
							<td class="midR">ASRC 特征分析</td>
							<td>结合多方研究分析，针对邮件行为、标头、以及来源和内容等复合型条件，持续打磨长效持久的特征过滤模型</td>
						</tr>
						<tr class="tr01">
							<td class="midR">ASRC 附件检测</td>
							<td>针对附件进行特征比对，结合附件分析引擎进行分类过滤，提高垃圾、威胁邮件检测率</td>
						</tr>
						<tr>
							<td class="midR">关键字过滤</td>
							<td>提供多语种关键字过滤数据库，可个性化调整过滤开关和自建过滤类型</td>
						</tr>
						<tr class="tr01">
							<td class="midR">自动过滤</td>
							<td>自动过滤学习引擎，可在分析学习垃圾邮件和正常邮件后，自动过滤</td>
						</tr>
						</tbody>
						<thead>
						<tr>
							<th width="100%" colspan="2" class="text-left">管理功能</th>
						</tr>
						</thead>
						<tbody>
						<tr>
							<td class="midR">管理政策</td>
							<td>提供浏览及调整各群组的过滤类别设定，并可设定类别明细派发规则</td>
						</tr>
						<tr class="tr01">
							<td class="midR">过滤条件</td>
							<td>管理员可根据企业内部内部电子邮件管理原则，设定放行、拦截、审核或延迟发送等条件</td>
						</tr>
						<tr>
							<td class="midR">群组设置</td>
							<td>提供企业内群组人员的账号设定，并能依群组内成员的管理需要，进行个性化服务设定</td>
						</tr>
						<tr class="tr01">
							<td class="midR">统计报表</td>
							<td>提供直观且多维度的统计信息——包括各项流量、拦截、排名、邮件、发件来源、IP来源、联机阻挡、病毒等各种统计辅助图表，并可导出、打印</td>
						</tr>
						<tr>
							<td class="midR">标准 LDAP 账号整合</td>
							<td>可整合标准 LDAP 通信协议，自动同步账号，并支持 SMTP 认证</td>
						</tr>
						<tr class="tr01">
							<td class="midR">分权管理</td>
							<td>分权管理依照公司部门分权分责管理，可定义管理员登入 web 访问权限，包含邮件记录，群组设置及统计报表等</td>
						</tr>
						<tr>
							<td class="midR">邮件拦截 / 标记服务</td>
							<td>提供分级标记传送模式、垃圾邮件拦截模式等多元化服务</td>
						</tr>
						</tbody>
						<thead>
						<tr>
							<th width="100%" colspan="2" class="text-left">自定义设置</th>
						</tr>
						</thead>
						<tbody>
						<tr>
							<td class="midR">拦截明细</td>
							<td>弹性发送拦截邮件明细，可直接通过明细预览或放行邮件</td>
						</tr>
						<tr class="tr01">
							<td class="midR">MySPAM</td>
							<td>通过个性化 MySPAM 的 WEB 服务，除可查看拦截邮件记录，亦可针对个人邮件进行查询、转发或回复。另：个人化管理功能含自动休假回复及自动密件抄送</td>
						</tr>
						</tbody>

					</table>
				</div>
				<p id="area3" class="subtitle blue02">增值模块</p>
				<div class="list02 scrollpoint sp-effect5 active " data-aos="fade-up" data-aos-delay="150">
					<table class="table table-hover ">
						<thead>
						<tr>
							<th colspan="2" class="text-left">增值功能</th>
						</tr>
						</thead>
						<tbody>
						<tr>
							<td width="22%" class="midR col-sm-2b col-xs-3">Multi-Way 模块</td>
							<td width="78%">包含邮件分流，异地容灾、分布式系统及运作服务</td>
						</tr>
						<tr class="tr01">
							<td class="midR">防病毒模块</td>
							<td>提供邮件双向（ inbound / outbound ）病毒扫描过滤，包含防毒信息及设定、病毒隔离、病毒统计报告等功能，可以拦截病毒，蠕虫等恶意软件扩散，更可升级至多层防毒引擎架构</td>
						</tr>
						<tr>
							<td class="midR">远程调阅模块</td>
							<td>在线直接查阅备份至远程的历史邮件。启用时备份设置显示“启用远程调阅模块”</td>
						</tr>
						<tr class="tr01">
							<td class="midR">附件审核模块</td>
							<td>针对附件内容关键词进行审计管控，并定时发送明细方便主管阅览。提供 8 种预设特征模板，启用后将开启：附件内容设置、附件文件格式选择及带密码附件判断条件等可选内容</td>
						</tr>
						<tr>
							<td class="midR">密码强度检测模块</td>
							<td>符合 ISO27001 定时检测密码强度规定，同时具备自动通知和统计报表功能。提供企业用户密码强度检测，有效降低黑客入侵风险</td>
						</tr>
						<tr class="tr01">
							<td class="midR">发件防伪认证模块</td>
							<td>防止认证通过账号，利用他人名义发信。以降低伪造企业人员发送 BEC 诈骗风险</td>
						</tr>
						<tr>
							<td class="midR">Advanced Defense Module</td>
							<td>可针对防御鱼叉式攻击。其运用云端沙盒技术，仿真产出静态特征，拦截附件及正文夹带 0-day 攻击、 APT 攻击工具。含有文件漏洞的攻击附件及利用 Drive by download 等攻击手法的威胁邮件。并提供拦截统计，展示漏洞编码和攻击工具及攻击群体等明细，可提供管理员作为内部信息安全强化的分析信息</td>
						</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
  </Layout>
</template>

<script>
import Layout from '@/components/common/Layout'
import Icon from '@/components/icon/Icon'
import Separator from '@/components/elements/separator/Separator'
import SectionTitle from '@/components/elements/sectionTitle/SectionTitle'
import Breadcrumb from '@/components/elements/breadcrumb/Breadcrumb'
export default {
  name: 'Product',
  components: {
    SectionTitle,
    Separator,
    Icon,
    Breadcrumb,
    Layout
  },
  data() {
    return {
    }
  }
}
</script>
<style lang="scss" scoped>
.main-title {
	font-size: 1.8em;
	color: #000;
	padding: 20px;
	margin-top: 0px;
}
.pdttext {
	margin: 0 -5px;
	p {
		font-size: 16px;
		line-height: 30px;
	}
	.blue03 {
		color: #003399;
		font-size: 22px;
	}
	.subtitle {
		margin: 50px 0 20px 0;
		font-size: 1.5em;
		font-weight: 700;
	}
	.blue02 {
		color: #0051ca;
	}
	.subtitle02 {
		margin: 40px 0 20px 0;
		font-size: 1.4em;
		font-weight: 350;
	}
}
.content {
	padding: 0 24px;
}
.mg_b50 {
	margin-bottom: 50px;
}
.border_red {
	border: #D70C18 1px solid;
}
.img-circle {
	border-radius: 50%;
}
.pditem {
	font-size: 1.2em;
	line-height: 28px;
	font-weight: 300;
	padding: 0 32px;
}
.list02 {
	margin: 4% 0;
	font-size: 15px;
	font-weight: 300;
}
.list02 th {
	font-size: 1.1em;
	font-weight: 350;
	background-color: #4898ed;
	color: #FFF;
}
.col-xs-3 {
	width: 25%
}
.text-left {
	text-align: left;
}
.midR {
	text-align: right;
	color: #0051ca;
}
.tr01, .tr01 td {
	background-color: #F2F2F2;
}
</style>
