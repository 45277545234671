<template>
  <Layout :footer-style="1">
		<div class="slider-area slider-style-1 variation-default height-400 bg_image"
				 data-black-overlay="2"
				 :style="{'background-image': `url(${require(`@/assets/images/banner/softnext1.jpg`)})`}">
		</div>
    <div class="container">
			<div id="area1" class="main-title "><img src="https://www.softnext.com.cn/images/icon_title.svg">Mail Archiving Expert 电子邮件归档管理</div>
			<div class="pdttext content">
				<p>企业在面对电子邮件管理时，最棘手的问题不外乎是该如何管理经年累月不断成长的电子邮件数据，能在符合法规的条件下长期保存，也能在需要举证时，快速搜寻找到关键的电子邮件。</p>
				<p> Mail Archiving Expert 电子邮件归档管理除了能够解决即时邮件归档，防止使用者误删邮件外，也提供完整的邮件生命周期管理，可依年份将邮件自动搬移至不同的储存媒介，也可依部门别保留不同年限的邮件。提供弹性的调阅权限管理，搭配查询结果指定给其它管理人员做再次查询的调阅流程，精确定位出需调查的信件，避免授权过度而看到不应被调阅的信件内容。</p>

				<p class="subtitle blue02">导入架构图</p>
				<p><span class="blue04">Journal Mode：</span>邮件主机的企业导入形式示意图</p>
				<p class="scrollpoint sp-effect5 text-center"><img src="https://www.softnext.com.cn/images/pic_mae01.svg" class="img-responsive" alt="Journal Mode - 建议自行架设电子邮件主机企业导入"></p>
				<p>&nbsp;</p>
				<p class="subtitle blue02">邮件归档示意图</p>
				<div class="row  scrollpoint sp-effect5">
					<div class="col-sm-6 ">
						<p class="blue03 mg_10"><i class="fa fa-sitemap"></i> 弹性分权管理</p>
						<p>自由设定分权角色，轻松应对复杂的组织架构，可随心设定出多种角色管理的排列组合。不管是跨部门、跨区域或层级式的管理结构，均可轻松构建。</p>
					</div>
					<div class="col-sm-6"><img src="https://www.softnext.com.cn/images/pic_mae03.svg" class="img-responsive" alt="Mail Archiving Expert电子邮件归档，弹性分权管理"></div>
				</div>
				<div class="row  scrollpoint sp-effect5">
					<div class="col-sm-6">
						<p class="blue03 mg_10"><i class="fa fa-search"></i> 多元调阅机制</p>
						<p>不论归档资料存储位置，均可以做条件式的过滤，从茫茫信海中调阅出需要的信件。不会因为保存越久，需要调阅的信件就淹没在庞大的数据里。</p>
					</div>
					<div class="col-sm-6"><img src="https://www.softnext.com.cn/images/pic_mae04.svg" class="img-responsive" alt="Mail Archiving Expert电子邮件归档，多元调阅机制"></div>
				</div>
				<div class="row  scrollpoint sp-effect5">
					<div class="col-sm-6">
						<p class="blue03 mg_10"><i class="fa fa-hourglass-half"></i> 邮件生命周期</p>
						<p>可做远端储存资源管理，可依年、月、日自动做归档目录切割，或者是依保存时间自动做归档空间迁移，并且可依不同部门做不同保存期限的设定。</p>
					</div>
					<div class="col-sm-6"><img src="https://www.softnext.com.cn/images/pic_mae05.svg" class="img-responsive" alt="Mail Archiving Expert电子邮件归档，邮件生命周期"></div>
				</div>
				<p class="subtitle blue02">产品特色</p>
				<div class="row scrollpoint sp-effect5">
					<div class="col-sm-offset-1 col-sm-2 pic02"> <img class="img-circle border_brown " src="https://www.softnext.com.cn/images/mase_item05.svg" width="100" height="100"> </div>
					<div class="col-sm-9">
						<p class="subtitle02 mase_title">轻松找出关联邮件行为</p>
						<ul class="pditem">
							<li>全文检索空间仅需原邮件容量约 10%</li>
							<li>多人协同审计</li>
							<li>针对搜索结果，可设条件二次搜索，精准定位可疑邮件内容</li>
						</ul>
					</div>
				</div>
				<div class="row scrollpoint sp-effect5">
					<div class="col-sm-offset-1 col-sm-2 pic02"> <img class="img-circle border_brown" src="https://www.softnext.com.cn/images/mase_item07.svg" width="100" height="100"> </div>
					<div class="col-sm-9">
						<p class="subtitle02 mase_title">轻松查询及举证</p>
						<ul class="pditem">
							<li>支持 Outlook、OWA 插件查询，无需改变用户使用习惯</li>
							<li>已归档信件可还原为 PST/NSF 格式，便于审计</li>
						</ul>
					</div>
				</div>
				<div class="row scrollpoint sp-effect5">
					<div class="col-sm-offset-1 col-sm-2 pic02"> <img class="img-circle border_brown " src="https://www.softnext.com.cn/images/mase_item06.svg" width="100" height="100"> </div>
					<div class="col-sm-9">
						<p class="subtitle02 mase_title">符合法规的邮件审计与归档</p>
						<ul class="pditem">
							<li>8 种常用个人敏感信息达量触发 </li>
							<li>异常行为一键查询</li>
							<li>邮件生命周期管理，符合各项法律法规要求</li>
						</ul>
					</div>
				</div>
				<p id="area2" class="subtitle blue02">功能列表</p>

				<!--功能表格-->
				<div class="list02 scrollpoint sp-effect5">
					<table class="table table-hover ">
						<thead>
						<tr>
							<th width="100%" colspan="2" class="text-left">事后举证</th>
						</tr>
						</thead>
						<tbody>
						<tr>
							<td class="midR col-sm-2b col-xs-3">邮件检索及查询</td>
							<td>记录公司所有收发的邮件，并提供条件查询、分类查询以及全文检索查询等功能，查询关键字提供精准、模糊或混合使用的输入方式。查询结果将提供详细邮件记录列表、统计模式报表以及统计图表</td>
						</tr>
						<tr class="tr01">
							<td class="midR">个人信息特征模板</td>
							<td>提供 4 种预设特征模板，并且提供自定义特征模板功能，可将符合网络安全法保护的数据，分别计算次数，组合使用情况。设定后的模板，可应用在策略管理及邮件查询</td>
						</tr>
						<tr>
							<td class="midR">审计管理</td>
							<td>可依据法规或管理政策进行邮件内容审计的监控设定，可调整设定条件或优先顺序，利于企业管理政策的实施与调整效率。也提供条件汇出、汇入，协助企业快速复制到其他分支机构，针对符合条件的邮件可立即通知审计人员进行审计</td>
						</tr>
						<tr class="tr01">
							<td class="midR">二次查询</td>
							<td>提供管理者限制查询范围后，再指定给其他管理者，达到查询结果中再次查询的邮件挖掘</td>
						</tr>
						</tbody>
						<thead>
						<tr>
							<th width="100%" colspan="2" class="text-left">归档还原</th>
						</tr>
						</thead>
						<tbody>
						<tr>
							<td class="midR">邮件归档</td>
							<td>除提供邮件索引、邮件本体（含附件）、系统设定文件以及资料库文件每日备份功能，并提供邮件归档政策，支持完整在线、近线以及离线邮件调阅机制。提供邮件生命周期管理机制设定以及邮件保存期限以符合相关法律法规，为企业达到降本增效目的</td>
						</tr>
						<tr class="tr01">
							<td class="midR">邮件转置</td>
							<td>提供 3 种转置来源（EML、PST、Exchange 封存信箱）的转置精灵设定流程，并且可以随时掌握转置进度，调整转置文件的顺序，每日外发成功率、重复率的统计通知信</td>
						</tr>
						<tr>
							<td class="midR">Journal 模式</td>
							<td>支持 Exchange、O365、Domino Notes、Postfix、MDaemon、Sendmail、Coremail，Umail，网易，腾讯等主流邮件主机的 MailCopy(日志)功能，记录归档邮件本体内容（含附件）</td>
						</tr>
						</tbody>
						<thead>
						<tr>
							<th width="100%" colspan="2" class="text-left">树状组织管理</th>
						</tr>
						</thead>
						<tbody>
						<tr>
							<td class="midR">账号管理</td>
							<td>依照企业管理政策提供管理账号并分配部分权限。可满足企业跨部门账号权限的赋予或是建立主管理员而封存系统预设最高账号，且能依群组内成员的管理需要，进行个性化功能开关的权限设定。另可搭配双账号调阅（增值模块）</td>
						</tr>
						<tr class="tr01">
							<td class="midR">标准 LDAP 账号整合</td>
							<td>可整合标准 LDAP 服务器，自动同步 Email 群组账号数据以及分权管理者账号，并支持 AD 阶层式群组结构，以实现分群管理的效益</td>
						</tr>
						</tbody>
						<thead>
						<tr>
							<th width="100%" colspan="2" class="text-left">个性化邮件管理</th>
						</tr>
						</thead>
						<tbody>
						<tr>
							<td class="midR">邮件复原</td>
							<td>用户若发生邮件资料损毁，可通过分类查询或 MyMail 个性化安全邮件服务，将邮件记录及内容进行复原</td>
						</tr>
						<tr class="tr01">
							<td class="midR">MyMail 个性化邮件</td>
							<td>用户登入个人管理界面，可使用个人邮箱、分类邮箱等功能，符合个人的使用习惯。且支持将查询到的信件，直接添加文字后，回复或转寄给其他收件人。另外提供 Outlook2010、2013、2016、2019 的安装插件，结合使用者原有的 Outlook 查询习惯，也可以释放个人端储存邮件的硬盘空间。搭配管理账号权限，可进行跨信箱搜寻，随时以一致的方式查询</td>
						</tr>
						</tbody>
					</table>
				</div>
				<!--功能表格-->
				<p id="area3" class="subtitle blue02">选购模块</p>
				<!--選購表格-->
				<div class="list02 scrollpoint sp-effect5">
					<table class="table table-hover ">
						<thead>
						<tr>
							<th class="list_title02 col-sm-2b col-xs-3">选购功能</th>
							<th class="list_title02">说明</th>
						</tr>
						</thead>
						<tbody>
						<tr>
							<td class="midR">Multi-way 模块</td>
							<td>包含邮件分流、异地容灾、分布式系统分工及运作服务</td>
						</tr>
						<tr class="tr01">
							<td class="midR">双账号认证模块</td>
							<td>提供场景模板选择，轻松导入邮件调阅的双签认证机制</td>
						</tr>
						<tr>
							<td class="midR">异常行为调查模块</td>
							<td>提供一键查询特定人员的邮件异常行为记录，也可定期统计异常行为超过警戒值的人员清单，有效结合顾问服务制度，建立起定期的离职、在职员工的查核办法</td>
						</tr>
						</tbody>
					</table>
				</div>
				<!--選購表格-->
			</div>
		</div>
  </Layout>
</template>

<script>
import Layout from '@/components/common/Layout'
import Icon from '@/components/icon/Icon'
import Separator from '@/components/elements/separator/Separator'
import SectionTitle from '@/components/elements/sectionTitle/SectionTitle'
import Breadcrumb from '@/components/elements/breadcrumb/Breadcrumb'
export default {
  name: 'Product',
  components: {
    SectionTitle,
    Separator,
    Icon,
    Breadcrumb,
    Layout
  },
  data() {
    return {
    }
  }
}
</script>
<style lang="scss" scoped>
.main-title {
	font-size: 1.8em;
	color: #000;
	padding: 20px;
	margin-top: 0px;
}
.pdttext {
	margin: 0 -5px;
	p {
		font-size: 16px;
		line-height: 30px;
	}
	.blue03 {
		color: #003399;
		font-size: 22px;
	}
	.subtitle {
		margin: 50px 0 20px 0;
		font-size: 1.5em;
		font-weight: 700;
	}
	.blue02 {
		color: #0051ca;
	}
	.subtitle02 {
		margin: 40px 0 20px 0;
		font-size: 1.4em;
		font-weight: 350;
	}
}
.content {
	padding: 0 24px;
}
.mg_b50 {
	margin-bottom: 50px;
}
.border_red {
	border: #D70C18 1px solid;
}
.img-circle {
	border-radius: 50%;
}
.pditem {
	font-size: 1.2em;
	line-height: 28px;
	font-weight: 300;
	padding: 0 32px;
}
.list02 {
	margin: 4% 0;
	font-size: 15px;
	font-weight: 300;
}
.list02 th {
	font-size: 1.1em;
	font-weight: 350;
	background-color: #4898ed;
	color: #FFF;
}
.col-xs-3 {
	width: 25%
}
.text-left {
	text-align: left;
}
.midR {
	text-align: right;
	color: #0051ca;
}
.tr01, .tr01 td {
	background-color: #F2F2F2;
}
.pdttext .pic02 {
	text-align: center;
	padding-top: 45px;
}
.border_brown {
	border: #DD6A37 1px solid;
}

.list02 th {
	color: #FFF;
}
</style>
