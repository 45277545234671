<template>
	<Layout :footer-style="1">
		<div class="slider-area slider-style-1 variation-default height-400 bg_image"
				 data-black-overlay="2"
				 :style="{'background-image': `url(${require(`@/assets/images/banner/softnext1.jpg`)})`}">
		</div>
		<div class="container">
			<div id="area1" class="main-title"><img src="https://www.softnext.com.cn/images/icon_title.svg">Mail SQR Expert 邮件审计管理</div>
			<div class="pdttext content">
				<p>邮件是企业间数据传递的主要渠道，也是大量敏感信息储存的媒介，如何有效管理邮件（邮件审计）已成为传输中的数据泄露防护（Data Loss Prevention，简称 DLP）的重要课题。企业在管理邮件时，首要任务为过滤病毒类邮件、审核敏感信息邮件，例如：信用卡号、电话、地址等，并预防员工因无心操作而造成的敏感信息泄露。最棘手的问题则是如何将不断成长累积的邮件数据，以符合法规的方式长期保存，并能在需要调查或举证时，快速找到关键邮件。</p>
				<p>Mail SQR Expert 邮件审计管理专家，提供企业通道式 DLP 完整的解决方案，以模块化的扩充功能设计，解决 DLP 加密的难题，并可深度整合第三方文件加解密或文件分级系统，提升成企业级内容感知型 DLP。功能设计遵循 3A 的管理循环，从分析 (Analysis) 收集判断，找到需管理的条件付诸行动 (Action)，最后借由审计 (Audit) 检查实际效果，找出需要再分析的情况，重新 3A 循环。</p>
				<h3 class="text-center blue03">Mail SQR Expert 管理循环</h3>
				<p class=" text-center "><img src="https://www.softnext.com.cn/images/pic_mse.svg" class="img-responsive" alt="Mail SQR Expert 电子邮件管理循环"></p>
				<p class="subtitle blue02">产品特色</p>
				<div class="row scrollpoint sp-effect5">
					<div class="col-sm-offset-1 col-sm-2 pic02"> <img class="img-circle border_green" src="https://www.softnext.com.cn/images/mase_item01.svg" width="100" height="100"> </div>
					<div class="col-sm-9">
						<p class="subtitle02 mase_title">邮件 DLP 防止数据泄露，提高企业竞争力</p>
						<ul class="pditem">
							<li>8 种常用个人敏感信息达量触发，部分模板有验算公式，保护敏感信息传递防止泄露</li>
							<li>可识别插入图片中的敏感信息，符合 DLP 个人信息安全原则，于发送时自动加密，并提供至少 3 种加密选择</li>
							<li>关键词自动组合过滤，防止敏感信息误发(*此为专案模块)</li>
						</ul>
					</div>
				</div>
				<div class="row scrollpoint sp-effect5">
					<div class="col-sm-offset-1 col-sm-2 pic02"> <img class="img-circle border_green " src="https://www.softnext.com.cn/images/mase_item02.svg" width="100" height="100"> </div>
					<div class="col-sm-9">
						<p class="subtitle02 mase_title">邮件挖掘，轻易找出关联邮件行为</p>
						<ul class="pditem">
							<li>全文检索空间仅需原邮件容量约 10%，储存空间与搜寻速度的最佳平衡</li>
							<li>多名审计人员可同时针对同一事件进行审计，协同调查记录审计过程</li>
							<li>针对异常行为的搜索结果，可再次设条件搜索，精准定位可疑邮件内容</li>
						</ul>
					</div>
				</div>
				<div class="row scrollpoint sp-effect5">
					<div class="col-sm-offset-1 col-sm-2 pic02"> <img class="img-circle border_green" src="https://www.softnext.com.cn/images/mase_item03.svg" width="100" height="100"> </div>
					<div class="col-sm-9">
						<p class="subtitle02 mase_title">符合树状组织的审核流程</p>
						<ul class="pditem">
							<li>提供树状群组与成员组织搭配分权管理账号结构，符合各类弹性审核流程</li>
							<li>7 种邮件过滤处理、3 种分权管理账号建立、3 种审核操作，轻松落实邮件策略</li>
							<li>自动加注免责宣告内容、多种加密传送方式，强化外发邮件保护</li>
						</ul>
					</div>
				</div>
				<p id="area2" class="subtitle blue02">功能列表</p>

				<!--功能表格-->
				<div class="list02 scrollpoint sp-effect5">
					<table class="table table-hover ">
						<thead>
						<tr>
							<th width="100%" colspan="2" class="text-left">分析管理</th>
						</tr>
						</thead>
						<tbody>
						<tr>
							<td class="midR col-sm-2b col-xs-3">个人信息特征模板</td>
							<td>提供 4 种预设特征模板，并且提供自定义特征模板功能，可将符合网络安全法保护的数据，分别计算次数，组合使用情况。设定后的模板，可应用在策略管理及邮件查询</td>
						</tr>
						<tr class="tr01">
							<td class="midR">统计报告</td>
							<td>分析邮件使用排名（依整体、群组、个人）、邮件处理状态、防毒统计报告（增值模块）、异常行为监控（增值模块）等多类图形化统计报表</td>
						</tr>
						</tbody>
						<thead>
						<tr>
							<th width="100%" colspan="2" class="text-left">行为管理</th>
						</tr>
						</thead>
						<tbody>
						<tr>
							<td class="midR">诈骗邮件防护</td>
							<td>当发信来源隐藏伪造特征或 DKIM 验证失败时，在邮件主题加以标注警示，提醒使用者提高警惕。信件对外发送时，加入 DKIM 签章，防止身份被冒用</td>
						</tr>
						<tr class="tr01">
							<td class="midR">数据安全管理</td>
							<td>支持内网、外网两套系统隔离，仅允许自己的内，外网邮箱账号进行交互</td>
						</tr>
						<tr>
							<td class="midR">政策管理</td>
							<td>可使用关键字阻挡黑函或白名单优先，提供全域各群组的邮件过滤设定总览，可调整设定条件或优先级，并快速分配相同过滤策略至指定的分权管理账号</td>
						</tr>
						<tr class="tr01">
							<td class="midR">邮件审核</td>
							<td>依据管理策略进行邮件的审核处理，提供群组分权审核管理。支持标注退回明细。搭配策略可实现先审后寄，自动强制附件 ZIP 或转发 PDF 加密</td>
						</tr>
						<tr>
							<td class="midR">邮件审核代理人</td>
							<td>提供群组分权审核的代理人设定，方便审核人休假时，不耽误审核流程</td>
						</tr>
						</tbody>
						<thead>
						<tr>
							<th width="100%" colspan="2" class="text-left">外发保护</th>
						</tr>
						</thead>
						<tbody>
						<tr>
							<td class="midR">账号速率控制</td>
							<td>通过发信频率条件设定，当发件人发信频率超过设定的频率与数量时，自动加入控制列表，防止因中毒导致大量发信影响主机运行。亦可依需求设定例外条件</td>
						</tr>
						<tr class="tr01">
							<td class="midR">延迟发送</td>
							<td>可将大文件或收件人多的批量邮件（非紧急或不重要的邮件），排在带宽充裕的流量离峰时段，协助企业妥善分配带宽资源</td>
						</tr>
						<tr>
							<td class="midR">误送信确认</td>
							<td>即重点邮件外发自审批，系统自动发送确认信，确认附件，正文内容，并可设置统一延迟发送</td>
						</tr>
						<tr class="tr01">
							<td class="midR">转发主机</td>
							<td>提供将用户大量寄往免费邮箱的邮件，转送至用户公司专属的发送平台或其他对外线路</td>
						</tr>
						<tr>
							<td class="midR">附件 ZIP 加密</td>
							<td>附件自动压缩加密后发送。亦可在审核完成发送时强制加密</td>
						</tr>
						<tr class="tr01">
							<td class="midR">邮件宣告语</td>
							<td>针对外发邮件，自动加注公司免责宣告内容，可依不同部门加签不同内容，并且对已加签过的邮件不再重覆加签</td>
						</tr>
						</tbody>
						<thead>
						<tr>
							<th width="100%" colspan="2" class="text-left">审计管理</th>
						</tr>
						</thead>
						<tbody>
						<tr>
							<td class="midR">邮件检索及查询</td>
							<td>记录公司所有收发的邮件，并提供条件查询、分类查询以及全文检索查询等功能，查询关键字提供精准、模糊或混合使用的输入方式。查询结果将提供详细邮件记录列表、统计模式报表以及统计图表</td>
						</tr>
						<tr class="tr01">
							<td class="midR">二次查询</td>
							<td>提供主管限制查询范围后，再分配给其他管理员，达到查询结果中再次查询的邮件挖掘</td>
						</tr>
						</tbody>
						<thead>
						<tr>
							<th width="100%" colspan="2" class="text-left">树状组织管理</th>
						</tr>
						</thead>
						<tbody>
						<tr>
							<td class="midR">账号管理</td>
							<td>依照企业管理政策提供管理账号并分配部份权限。可满足企业跨部门账号权限的赋予或是建立主管理员而封存系统预设最高账号，且能依群组内成员的管理需要，进行个性化功能开关的权限设定。另可搭配双账号调阅（增值模块）</td>
						</tr>
						<tr class="tr01">
							<td class="midR">标准 LDAP 账号整合</td>
							<td>可整合标准 LDAP 服务器，自动同步 Email 群组账号数据以及分权管理者账号，并支持 AD 阶层式群组结构，以实现分群管理的效益</td>
						</tr>
						</tbody>
						<thead>
						<tr>
							<th width="100%" colspan="2" class="text-left">邮件归档还原</th>
						</tr>
						</thead>
						<tbody>
						<tr>
							<td class="midR">邮件备份</td>
							<td>提供邮件记录、邮件内容（含附件）、及系统设定文件三部份内容的备份。将每日邮件备份成一个文件夹，并能逐月寻找某日期的邮件备份数据</td>
						</tr>
						<tr class="tr01">
							<td class="midR">邮件复原</td>
							<td>使用者若发生邮件数据毁损，可通过分类查询或 MyMail 个性化安全邮件服务，能将邮件记录及内容进行复原发送</td>
						</tr>
						</tbody>
					</table>
				</div>
				<!--功能表格-->
				<p id="area3" class="subtitle blue02">增值模块</p>
				<!--選購表格-->
				<div class="list02 scrollpoint sp-effect5">
					<table class="table table-hover ">
						<thead>
						<tr>
							<th colspan="2" class="text-left">增值功能</th>
						</tr>
						</thead>
						<tbody>
						<tr>
							<td width="22%" class="midR col-sm-2b col-xs-3">Multi-Way 模块</td>
							<td width="78%">包含邮件分流，异地容灾、分布式系统及运作服务</td>
						</tr>
						<tr class="tr01">
							<td class="midR">防毒模块</td>
							<td>提供邮件双向（Inbound / Outbound）病毒扫描过滤，包含防毒信息及设定、病毒隔离、病毒统计报告等功能，可以拦截病毒、蠕虫等恶意程序扩散，更可提升至多层防毒引擎构架</td>
						</tr>
						<tr>
							<td class="midR">Mail Archiving 模块</td>
							<td>落实弹性化邮件归档政策，提供完整的在线、近线以及离线等多类邮件调阅功能，并提供邮件生命周期管理机制，设定邮件保存期限以符合当地法规要求</td>
						</tr>
						<tr class="tr01">
							<td class="midR">内部信记录模块</td>
							<td>支持多类邮件服务器进行内部信记录、搜寻、全文检索、审计、调阅及报表统计等功能</td>
						</tr>
						<tr>
							<td class="midR">双账号认证模块</td>
							<td>提供场景模板选择，轻松导入邮件调阅的双签认证机制</td>
						</tr>
						<tr class="tr01">
							<td class="midR">邮件加解密模块</td>
							<td>提供 S/MIME、PGP 公私钥设定，可通过签章加密策略设定需要签章 / 加密的信件条件。搭配策略管理的条件使用，实现即使已审核放行的邮件，依然自动加密并同时自动产生公私钥，并通过解密邮件发送至收件人</td>
						</tr>
						<tr>
							<td class="midR">异常行为调查模块</td>
							<td>提供一键查询特定人员的邮件异常行为记录，也可定期统计异常行为超过警戒值的人员清单，有效结合顾问服务制度，建立起定期的离职、在职员工的核查办法</td>
						</tr>
						<tr class="tr01">
							<td class="midR">转发 PDF 密码加密高级模块</td>
							<td>提供转发 PDF 加密时，PDF 文件可添加浮水印及设置文件权限，例如：禁止印刷，禁止编辑…等</td>
						</tr>
						</tbody>
					</table>
				</div>
				<!--選購表格-->
			</div>
		</div>
	</Layout>
</template>

<script>
import Layout from '@/components/common/Layout'
import Icon from '@/components/icon/Icon'
import Separator from '@/components/elements/separator/Separator'
import SectionTitle from '@/components/elements/sectionTitle/SectionTitle'
import Breadcrumb from '@/components/elements/breadcrumb/Breadcrumb'
export default {
	name: 'Product',
	components: {
		SectionTitle,
		Separator,
		Icon,
		Breadcrumb,
		Layout
	},
	data() {
		return {
		}
	}
}
</script>
<style lang="scss" scoped>
.main-title {
	font-size: 1.8em;
	color: #000;
	padding: 20px;
	margin-top: 0px;
}
.pdttext {
	margin: 0 -5px;
	p {
		font-size: 16px;
		line-height: 30px;
	}
	.blue03 {
		color: #003399;
		font-size: 22px;
	}
	.subtitle {
		margin: 50px 0 20px 0;
		font-size: 1.5em;
		font-weight: 700;
	}
	.blue02 {
		color: #0051ca;
	}
	.subtitle02 {
		margin: 40px 0 20px 0;
		font-size: 1.4em;
		font-weight: 350;
	}
}
.content {
	padding: 0 24px;
}
.mg_b50 {
	margin-bottom: 50px;
}
.border_red {
	border: #D70C18 1px solid;
}
.img-circle {
	border-radius: 50%;
}
.pditem {
	font-size: 1.2em;
	line-height: 28px;
	font-weight: 300;
	padding: 0 32px;
}
.list02 {
	margin: 4% 0;
	font-size: 15px;
	font-weight: 300;
}
.list02 th {
	font-size: 1.1em;
	font-weight: 350;
	background-color: #4898ed;
	color: #FFF;
}
.col-xs-3 {
	width: 25%
}
.text-left {
	text-align: left;
}
.midR {
	text-align: right;
	color: #0051ca;
}
.tr01, .tr01 td {
	background-color: #F2F2F2;
}
.border_green {
	border: #00865A 1px solid;
}
.pdttext .pic02 {
	text-align: center;
	padding-top: 45px;
}
</style>
