<template>
    <div>
    <div class="col-lg-12">
        <ul class="nav nav-pills rwt-portfolio-filter filter-button-default liststyle mb--40"
            id="pills-tab"
            role="tablist"
            data-aos="fade-up">
            <li class="nav-item list-item"
                role="presentation"
                v-for="(filter, index) in filters"
                :key="`tab-button-${filter.id}`">
                <button class="nav-link"
                        :class="{'active': index === 0}"
                        :id="`pills-${filter.text}-tab`"
                        data-bs-toggle="pill"
                        :data-bs-target="`#pills-${filter.text}`"
                        type="button"
                        role="tab"
                        :aria-controls="`pills-${filter.text}`"
                        :aria-selected="index === activeTab ? 'true' : 'false'"
                        @click="activeTab = index">
                    {{ filter.text }}
                </button>
            </li>
        </ul>
    </div>
    <div class="tab-content" id="pills-tabContent">
        <div class="tab-pane fade"
             :class="{'show active': index === 0}"
             :id="`pills-${filter.text}`"
             role="tabpanel"
             :aria-labelledby="`pills-${filter.text}-tab`"
             v-for="(filter, index) in filters"
             :key="`tab-pane-${filter.id}`">
            <div class="row row--15">
                <template v-if="filter.id === 1">
                    <div class="row mt_dec--30 row--15">
                        <div class="col-lg-3 col-md-3 col-3 mt--30"
                             v-for="(gallery, index) in galleryData"
                             :key="index">
                            <div class="rn-gallery" data-aos="fade-up">
                                <div class="thumbnail">
                                    <img class="radius-small"
                                         :src="gallery.thumb"
                                         alt="Video Thumbnail"/>
                                    <div class="title">{{gallery.title}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
                <template v-else>
                    <div class="row mt_dec--30 row--15">
                        <div class="col-lg-3 col-md-3 col-3 mt--30"
                             v-for="(gallery, index) in galleryData2"
                             :key="index">
                            <div class="rn-gallery" data-aos="fade-up">
                                <div class="thumbnail">
                                    <img class="radius-small"
                                         :src="gallery.thumb"
                                         alt="Video Thumbnail"/>
                                    <div class="title">{{gallery.title}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
            </div>
        </div>
    </div>
    </div>
</template>

<script>
export default {
    name : 'hornors',
    data() {
        return {
            filters: [
                {
                    id: 1,
                    text: '权威资质',
                },
                {
                    id: 2,
                    text: '专利软著',
                }
            ],
            activeFilter: '',
            activeTab: 0,
            galleryData: [
                {
                    id: 1,
                    thumb: require(`@/assets/images/hornors/ISO9001.jpg`),
                    src: require(`@/assets/images/hornors/ISO9001.jpg`),
                    type: 'image',
                    title: 'ISO9001'
                },
                {
                    id: 2,
                    thumb: require(`@/assets/images/hornors/ISO9001-en.jpg`),
                    src: require(`@/assets/images/hornors/ISO9001-en.jpg`),
                    type: 'image',
                    title: 'ISO9001'
                },
                {
                    id: 3,
                    thumb: require(`@/assets/images/hornors/ISO20000.jpg`),
                    src: require(`@/assets/images/hornors/ISO20000.jpg`),
                    type: 'image',
                    title: 'ISO20000'
                },
                {
                    id: 4,
                    thumb: require(`@/assets/images/hornors/ISO20000-en.jpg`),
                    src: require(`@/assets/images/hornors/ISO20000-en.jpg`),
                    type: 'image',
                    title: 'ISO20000'
                },
                {
                    id: 5,
                    thumb: require(`@/assets/images/hornors/ISO27001.jpg`),
                    src: require(`@/assets/images/hornors/ISO27001.jpg`),
                    type: 'image',
                    title: 'ISO27001'
                },
                {
                    id: 6,
                    thumb: require(`@/assets/images/hornors/ISO27001-en.jpg`),
                    src: require(`@/assets/images/hornors/ISO27001-en.jpg`),
                    type: 'image',
                    title: 'ISO27001'
                },
            ],
            galleryData2: [
                {
                    id: 1,
                    thumb: require(`@/assets/images/hornors/rz1.jpg`),
                    src: require(`@/assets/images/hornors/rz1.jpg`),
                    type: 'image',
                    title: '软著-中炘SSO'
                },
                {
                    id: 2,
                    thumb: require(`@/assets/images/hornors/rz2.jpg`),
                    src: require(`@/assets/images/hornors/rz2.jpg`),
                    type: 'image',
                    title: '软著-中炘云盾'
                },
							{
								id: 3,
								thumb: require(`@/assets/images/hornors/rz3.jpg`),
								src: require(`@/assets/images/hornors/rz3.jpg`),
								type: 'image',
								title: '软著-炘岩办公'
							},
							{
								id: 4,
								thumb: require(`@/assets/images/hornors/rz4.jpg`),
								src: require(`@/assets/images/hornors/rz4.jpg`),
								type: 'image',
								title: '软著-中炘日志管理'
							},
							{
								id: 5,
								thumb: require(`@/assets/images/hornors/rz5.jpg`),
								src: require(`@/assets/images/hornors/rz5.jpg`),
								type: 'image',
								title: '软著-中炘监控'
							},
							{
								id: 6,
								thumb: require(`@/assets/images/hornors/rz6.jpg`),
								src: require(`@/assets/images/hornors/rz6.jpg`),
								type: 'image',
								title: '软著-中炘调管'
							},
            ]
        }
    }
}
</script>
<style scoped lang="scss">
.thumbnail {
    padding: 16px;
    background-color: white;
    border-radius: 8px;
    .title {
        text-align: center;
        margin-top: 12px;
    }
}
</style>
